import React, { useEffect, useState } from 'react'
import BlogPageViewModel from '../../view-models/blog-page-viewmodel';
import { Routes } from '../../navigation/routes';
import { FaCheckCircle } from "react-icons/fa";
    
import {
    EmailShareButton,
    FacebookShareButton,
    GabShareButton,
    HatenaShareButton,
    InstapaperShareButton,
    LineShareButton,
    LinkedinShareButton,
    LivejournalShareButton,
    MailruShareButton,
    OKShareButton,
    PinterestShareButton,
    PocketShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    ViberShareButton,
    VKShareButton,
    WhatsappShareButton,
    WorkplaceShareButton,
  } from "react-share";

  import {
    EmailIcon,
    FacebookIcon,
    FacebookMessengerIcon,
    GabIcon,
    HatenaIcon,
    InstapaperIcon,
    LineIcon,
    LinkedinIcon,
    LivejournalIcon,
    MailruIcon,
    OKIcon,
    PinterestIcon,
    PocketIcon,
    RedditIcon,
    TelegramIcon,
    TumblrIcon,
    TwitterIcon,
    ViberIcon,
    VKIcon,
    WeiboIcon,
    WhatsappIcon,
    WorkplaceIcon,
    XIcon,
  } from "react-share";

import { CommentSection} from 'react-comments-section'
import 'react-comments-section/dist/index.css'
import Footer from '../../components/landing-page-items/footer';
import { Comment, CommentReply, DeleteCommentData, EditedComment } from '../../../models/blog/blog-attributes';
import Navbar from '../../components/landing-page-items/navbar';
import { Helmet } from 'react-helmet-async';
import PopupModal from '../../components/common/popup-modal';
import { FaYoutube } from "react-icons/fa";
import BlogsListViewModel from '../../view-models/blog-list-viewmodel';
import InterestForm from "../../components/common/interestForm";

const BlogPage = () => {
    const { 
        isLoading,
        blogPost,
        user,
        navigate,
        isLiked,
        isDisliked,
        handleLike,
        handleDislike,
        blogComments,
        handleAddNewComment,
        handleAddNewCommentReply,
        handleEditComment,
        handleDeleteComment,
        openPopup,
        setOpenPopup,
        interestFormData,
        setInterestFormData,
        handleSubmitInterests,
        isPostInterestSuccess,
        setIsPostInterestSuccess,
        handleBlogIndex
    } = BlogPageViewModel();

    const { blogs} = BlogsListViewModel();

    return (
        <div>
            <Helmet>
                <title>{blogPost?.attributes?.title}</title>
                <meta name='description' content={ blogPost?.attributes?.metaTags}/>
            </Helmet>
            <Navbar />
            {blogPost?.attributes?.previewImage?.data?.attributes?.url &&
                <div className='w-full relative'>
                    <img 
                        src={blogPost?.attributes?.previewImage?.data?.attributes?.url}
                        className='w-full'
                    />
                    <h1 style={{color: blogPost?.attributes?.titleColor}} className='absolute top-1/2 transform -translate-y-1/2 md:text-[58px] text-[22px] font-bold ml-4'>{blogPost?.attributes?.title}</h1>
                </div>
            }
            <div
              dangerouslySetInnerHTML={{ __html: blogPost?.attributes?.pageData ?? '' }}
            />
            <hr className='border-[1px] border-[#6b6b6b67]' />
            <div className='w-full'>
                <div className="flex space-x-4 justify-end p-4">
                    <button
                        onClick={handleLike}
                        className={`btn bg-slate-100 hover:ring-2 transition-all ring-green-600 focus:outline-none p-2 rounded-md`}
                    >
                        <svg width="30px" height="30px" viewBox="0 0 24 24" fill='none' xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.9 4.5C15.9 3 14.418 2 13.26 2c-.806 0-.869.612-.993 1.82-.055.53-.121 1.174-.267 1.93-.386 2.002-1.72 4.56-2.996 5.325V17C9 19.25 9.75 20 13 20h3.773c2.176 0 2.703-1.433 2.899-1.964l.013-.036c.114-.306.358-.547.638-.82.31-.306.664-.653.927-1.18.311-.623.27-1.177.233-1.67-.023-.299-.044-.575.017-.83.064-.27.146-.475.225-.671.143-.356.275-.686.275-1.329 0-1.5-.748-2.498-2.315-2.498H15.5S15.9 6 15.9 4.5zM5.5 10A1.5 1.5 0 0 0 4 11.5v7a1.5 1.5 0 0 0 3 0v-7A1.5 1.5 0 0 0 5.5 10z" fill={isLiked ? 'green' : 'gray'}/>
                        </svg>
                    </button>

                    <button
                        onClick={handleDislike}
                        className={`btn bg-slate-100 hover:ring-2 transition-all ring-red-600 focus:outline-none p-2 rounded-md`}
                    >
                        <svg width="30px" height="30px" viewBox="0 0 24 24" fill='none' xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.1 20.5c0 1.5 1.482 2.5 2.64 2.5.806 0 .869-.613.993-1.82.055-.53.121-1.174.267-1.93.386-2.002 1.72-4.56 2.996-5.325V8C15 5.75 14.25 5 11 5H7.227C5.051 5 4.524 6.432 4.328 6.964A15.85 15.85 0 0 1 4.315 7c-.114.306-.358.546-.638.82-.31.306-.664.653-.927 1.18-.311.623-.27 1.177-.233 1.67.023.299.044.575-.017.83-.064.27-.146.475-.225.671-.143.356-.275.686-.275 1.329 0 1.5.748 2.498 2.315 2.498H8.5S8.1 19 8.1 20.5zM18.5 15a1.5 1.5 0 0 0 1.5-1.5v-7a1.5 1.5 0 0 0-3 0v7a1.5 1.5 0 0 0 1.5 1.5z" fill={isDisliked ? 'red' : 'gray'}/>
                        </svg>
                    </button>
                </div>
                {/* <div className='w-full px-4 py-2 text-2xl font-bold flex items-center gap-2 justify-end'>
                    Share
                    <img 
                        src='/assets/images/share.png'
                        className='w-6'
                    />
                </div> */}
                <div className='flex gap-8 sm:flex-row flex-col justify-between'>
                    <div>
                        <p className="flex px-4 font-semibold">Follow us on</p>
                        <div className='flex flex-wrap items-center gap-4 px-4'>
                            <a target='_blank' href='https://www.facebook.com/SpArtsTech/'><FacebookIcon size={38} round={true} /></a>
                            <a target='_blank' href='https://in.linkedin.com/company/sparts-technologies'><LinkedinIcon size={38} round={true}/></a>
                            <a target='_blank' href='https://www.instagram.com/spartstech/'><img src="/assets/images/Instagram_icon.png" className='w-9' /></a>
                            <a target='_blank' href='https://www.youtube.com/channel/UCHlKcjvUkQkPLycrALD3LmA'><FaYoutube size={38} color='red'/></a>
                        </div>
                    </div>
                    <div>
                    <p className="flex sm:justify-end sm:mr-80 px-4 font-semibold">Share this link via</p>
                    <div className='flex flex-wrap items-center justify-end gap-4 px-4'>
                    <EmailShareButton url={window.location.href} openShareDialogOnClick={true}>
                        <EmailIcon size={38} round={true}/>
                    </EmailShareButton>
                    <FacebookShareButton url={window.location.href} openShareDialogOnClick={true}>
                        <FacebookIcon size={38} round={true}/>
                    </FacebookShareButton>
                    {/* <InstapaperShareButton url={window.location.href} openShareDialogOnClick={true}>
                        <InstapaperIcon size={38} round={true}/>
                    </InstapaperShareButton> */}
                    <LinkedinShareButton url={window.location.href} openShareDialogOnClick={true}>
                        <LinkedinIcon size={38} round={true}/>
                    </LinkedinShareButton>
                    <PinterestShareButton url={window.location.href} openShareDialogOnClick={true} media={blogPost?.attributes?.previewImage?.data?.attributes?.url ?? ''}>
                        <PinterestIcon size={38} round={true}/>
                    </PinterestShareButton>
                    <RedditShareButton url={window.location.href} openShareDialogOnClick={true}>
                        <RedditIcon size={38} round={true}/>
                    </RedditShareButton>
                    <TelegramShareButton url={window.location.href} openShareDialogOnClick={true}>
                        <TelegramIcon size={38} round={true}/>
                    </TelegramShareButton>
                    <TwitterShareButton url={window.location.href} openShareDialogOnClick={true}>
                        <XIcon size={38} round={true}/>
                    </TwitterShareButton>
                    <WhatsappShareButton url={window.location.href} openShareDialogOnClick={true}>
                        <WhatsappIcon size={38} round={true}/>
                    </WhatsappShareButton>
                    <TumblrShareButton url={window.location.href} openShareDialogOnClick={true}>
                        <TumblrIcon size={38} round={true}/>
                    </TumblrShareButton>
                    </div>
                    </div>
                </div>
                <div>
                    <CommentSection
                        currentUser={user?.user?.username ? {
                            currentUserId: (user?.user?.id ?? 0).toString(),
                            currentUserImg: `https://ui-avatars.com/api/name=${user?.user?.username}&background=random`,
                            currentUserProfile: '',
                            currentUserFullName: `${user?.user?.username}`,
                        } : null}
                        logIn={{
                            loginLink: 'https://students.sparts.app/login',
                            signupLink: 'https://students.sparts.app/sign-up',
                        }}
                        commentData={blogComments}
                        onSubmitAction={(data: Comment) => handleAddNewComment(data)}
                        onReplyAction={(data: CommentReply) => handleAddNewCommentReply(data)}
                        onDeleteAction={(data: DeleteCommentData) => handleDeleteComment(data)}
                        onEditAction={(data: EditedComment) => handleEditComment(data)}
                        currentData={(data: any) => {
                            // console.log('curent data', data)
                        }}
                    />
                </div>
            </div>
            <div className='flex justify-between sm:px-20 p-4 py-8'>
                <button disabled={blogs[0]?.id === blogPost?.id} className={` ${blogs[0]?.id === blogPost?.id ? 'bg-[#f5dc9f]' : 'bg-[#f9cb54]'} h-12 w-32 text-xl text-white font-semibold`} onClick={() => handleBlogIndex(blogs, blogPost?.id, 'prev')}>&lt;&lt; Prev</button>
                <button disabled={blogs[blogs.length-1]?.id === blogPost?.id} className={` ${blogs[blogs.length-1]?.id === blogPost?.id ? 'bg-[#f5dc9f]' : 'bg-[#f9cb54]'} h-12 w-32 text-xl text-white font-semibold`} onClick={() => handleBlogIndex(blogs, blogPost?.id, 'next')}>Next &gt;&gt;</button>
            </div>
            <Footer />
            {openPopup &&
                <InterestForm
                    handleSubmitInterests={handleSubmitInterests}
                    interestFormData={interestFormData}
                    setInterestFormData={setInterestFormData}
                    setOpenPopup ={setOpenPopup}
                />}
            {isPostInterestSuccess && <PopupModal>
                <div className="flex flex-col w-full p-6 bg-[#f5c750] rounded-md shadow-md">
                    <FaCheckCircle style={{ color: '#3b82f6', fontSize: '3rem', margin: 'auto' }} />
                    <h2 className="text-lg font-semibold mb-4 mt-0 text-center">Thank you for sharing</h2>
                    <p className='text-center'>Your interest is recorded.</p>
                    <p className='text-center'>We will soon get back to you.</p>
                    <button
                            type="button"
                            onClick={() => setIsPostInterestSuccess(false)}
                            className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400"
                        >
                            Go back
                    </button>
                </div>
            </PopupModal>}
        </div>
    )
}

export default BlogPage