import { AcademyInterestsData } from "../../../../models/academy-profile/academy-profile-interests";
import { InterestsPostData } from "../../../../models/blog/interests-post-data";
import PopupModal from "../popup-modal";

interface InterestFormProps {
    handleSubmitInterests: (data: InterestsPostData) => Promise<void>
    interestFormData: InterestsPostData | AcademyInterestsData;
    setInterestFormData: React.Dispatch<React.SetStateAction<InterestsPostData>>;
    setOpenPopup: (arg0: boolean) => void
}

const InterestForm = ({
    handleSubmitInterests,
    interestFormData,
    setInterestFormData,
    setOpenPopup
}:InterestFormProps) => {
    return (
        <PopupModal>
                <div className="flex flex-col w-full p-6 bg-[#f5c750] rounded-md shadow-md">
                    <img src='/assets/images/logo.svg' className='h-12'/>
                    <h2 className="text-lg font-semibold mb-4 mt-0 text-white text-center">Share your interests</h2>
                    <form onSubmit={(event) => { event.preventDefault(); handleSubmitInterests(interestFormData)}}>
                        <div className="mb-4">
                        <label htmlFor="name" className="block text-sm font-medium text-white">
                            Name
                        </label>
                        <input
                            type="text"
                            id="name"
                            value={interestFormData?.name}
                            onChange={(e) => setInterestFormData(prevVal => ({...prevVal, name: e.target.value}))}
                            placeholder="Enter your name"
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 outline-none"
                            required
                        />
                        </div>

                        <div className="mb-4">
                        <label htmlFor="contact" className="block text-sm font-medium text-white">
                            Contact
                        </label>
                        <input
                            type="text"
                            id="contact"
                            value={interestFormData?.contact || ''}
                            onChange={(e) => setInterestFormData(prevVal => ({...prevVal, contact: e.target.value}))}
                            placeholder="Enter contact details"
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 outline-none"
                            required
                        />
                        </div>
                        <div className="mb-4">
                        <label htmlFor="contact" className="block text-sm font-medium text-white">
                            Your interests
                        </label>
                        <textarea
                            id="interests"
                            value={interestFormData?.interests}
                            onChange={(e) => setInterestFormData(prevVal => ({...prevVal, interests: e.target.value}))}
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 outline-none"
                            required
                        ></textarea>
                        </div>

                        <div className="flex justify-end gap-2">
                        <button
                            type="button"
                            onClick={() => setOpenPopup(false)}
                            className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="px-4 py-2 bg-[#4584eb] text-white rounded-md hover:bg-[#3362ad]"
                        >
                            Submit
                        </button>
                        </div>
                    </form>
                </div>
            </PopupModal>
    )
}

export default InterestForm;